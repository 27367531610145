import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import MapEmbed from "../components/mapEmbed";

export default function Contact({ data }) {
  const { seo, contactPage: page } = data.wpPage;

  const coords = {
    address: page.googleMapsData.address,
    lat: page.googleMapsData.latitude,
    long: page.googleMapsData.longitude,
  };

  return (
    <Layout>
      <Seo seo={seo} />

      <div className="contact__container">
        <div className="contact__text">
          <h2
            className="contact__title text-blue"
            dangerouslySetInnerHTML={{ __html: page.heading }}
          />
          <p
            className="contact__paragraph"
            dangerouslySetInnerHTML={{ __html: page.paragraph }}
          />

          <div className="contact__section">
            <p className="contact__label">Email</p>
            <p className="contact__paragraph">{page.email}</p>
          </div>

          <div className="contact__section">
            <p className="contact__label">Telephone</p>
            <p className="contact__paragraph">{page.telephone}</p>
          </div>

          <div className="contact__section">
            <p className="contact__label">Fax</p>
            <p className="contact__paragraph">{page.fax}</p>
          </div>

          <div className="contact__section">
            <p className="contact__label">Media and corporate relations</p>
            <p
              className="contact__paragraph"
              dangerouslySetInnerHTML={{
                __html: page.mediaAndCorporateRelationsContact,
              }}
            />
          </div>
        </div>
        <div className="contact__map-wrapper">
          <MapEmbed {...{ coords }} />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    wpPage(slug: { eq: "contact" }) {
      id
      contactPage {
        heading
        paragraph
        address
        email
        telephone
        #fax
        mediaAndCorporateRelationsContact
        googleMapsData {
          address
          latitude
          longitude
        }
      }
      seo {
        fullHead
      }
    }
  }
`;
