import React from "react";

const MapEmbed = ({ coords }) => {
  return (
    <div className="contact__map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15883.98680540856!2d-0.19726761407327517!3d5.567502691944395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9a705b243209%3A0xbecb7ac2d07dff6c!2s2%20Johnson%20Sirleaf%20Rd%2C%20Accra!5e0!3m2!1sen!2sgh!4v1627235822401!5m2!1sen!2sgh"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen={true}
        loading="lazy"
        title={coords.address}
      ></iframe>
    </div>
  );
};

export default MapEmbed;
